<template>
  <div>
    <form-section
      :title="$t('courierSet.sideNavigation.additionalServices')"
      :tooltip="{ icon: 'mdi-help-circle-outline' }"
    >
      <template v-slot:message>
        <AdditionalServicesTooltipMessage />
      </template>
      <div
        v-for="serv in courierAddtionalServices"
        :key="serv.id"
        class="ma-3"
      >
        <service-field
          :id="serv.id"
          :label="serv.name"
          :messages="serv.description"
          :remark="serv.remark"
          :code="serv.serviceId"
          :selected-service="isSelected(serv)"
          :active-service="isActive(serv)"
          :visible-service="isVisible(serv)"
          :parameters="serv.parameters"
          @@activate="activate"
          @@deactivate="deactivate(serv)"
          @@update="updateService"
        >
          <div
            v-if="serv.id === 2 && isSelected(serv)"
            class="additional-inputs"
          >
            <text-field
              v-model="getAmbroExpressDefaultValues.insuranceValue"
              :title="$t('courierSet.defaultAmount')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required|double:2|greaterThanZero' : ''"
            />
          </div>

          <div
            v-if="serv.id === 1 && isSelected(serv)"
            class="additional-inputs"
          >
            <text-field
              v-model="getAmbroExpressDefaultValues.retAccountNo"
              :title="$t('courierSet.accountNumber')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required|numeric' : ''"
            />
          </div>
        </service-field>
      </div>
    </form-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import additionalServicesMixin from '@/mixins/additionalServicesMixin';
import { ambroExpressAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/AmbroExpress/AddtionalServices';
import AdditionalServicesTooltipMessage from '@/components/tooltips/contents/AdditionalServicesTooltipMessage.vue';

export default {
  name: 'AdditionalServices',
  components: {
    AdditionalServicesTooltipMessage,
  },
  mixins: [additionalServicesMixin],
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: [],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAmbroExpressDefaultValues() {
      return this.getConfigurationSet().ambroExpressDefaultValues;
    },
  },
  methods: {
    ...mapActions('spedition', ['setAdditionalServices']),
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        clientEntityState: this.isNew || !this.id ? 4 : state,
        id: this.id,
        tenantId: this.oidcUser.tenantId,
        services: this.additionalServices,
      };
      this.setAdditionalServices({ additionalServices: serviceParameters });
    },
    clearAdditionalData(service) {
      if (service.id === 2) {
        this.getAmbroExpressDefaultValues.insuranceValue = 0;
      }
      if (service.id === 1) {
        this.getAmbroExpressDefaultValues.retAccountNo = '';
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
