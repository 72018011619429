<template>
  <div>
    <form-section :title="$t('courierSet.labelSettings.labelPrintSettings')">
      <v-row>
        <v-col>
          <select-field
            v-model="getDefaultValues.labelFormat"
            :title="$t('courierSet.labelSettings.labelPrintFormat')"
            rules="required"
            :filed-items="labelFormats"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      labelFormats: [
        { id: 1, name: 'PDF' },
        { id: 2, name: 'PDFA4' },
      ],
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDefaultValues() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    if (this.isNew) {
      this.getDefaultValues.labelFormat = 1;
    }
  },
};
</script>

<style scoped></style>
