<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />
      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />

      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />

      <AdditionalPayments
        v-show="currentComponent === 'AdditionalPayments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import { AmbroExpress } from '@/constants/Speditors/AmbroExpress/configuration/AmbroExpress';
import ServiceAuthorization from '@/views/Speditors/AmbroExpress/Forms/ServiceAuthorization.vue';
import AdditionalServices from '@/views/Speditors/AmbroExpress/Forms/AdditionalServices.vue';
import LabelSettings from '@/views/Speditors/AmbroExpress/Forms/LabelSettings.vue';
import ContentAndComments from '@/views/Speditors/AmbroExpress/Forms/ContentAndComments.vue';
import AdditionalPayments from '@/views/Speditors/AmbroExpress/Forms/AdditionalPayments.vue';
import { v4 as uuidv4 } from 'uuid';
import { TEST_AMBRO_EXPRESS_URL } from '@/constants/Speditors/testApi.js';
import { PROD_AMBRO_EXPRESS_URL } from '@/constants/Speditors/prodApi.js';

export default {
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    LabelSettings,
    ContentAndComments,
    AdditionalPayments,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'AmbroExpress',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.sideNavigation.authorizationData' },
      { code: 'AdditionalServices', name: 'courierSet.sideNavigation.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.sideNavigation.labelSettings' },
      { code: 'AdditionalPayments', name: 'courierSet.sideNavigation.payments' },
      { code: 'ContentAndComments', name: 'courierSet.sideNavigation.contentAndComments' },
    ],
  }),
  computed: {
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `http://${PROD_AMBRO_EXPRESS_URL}/api.asmx?WSDL`;
      }
      return `http://${TEST_AMBRO_EXPRESS_URL}/api.asmx?WSDL`;
    },
  },
  methods: {
    setNewConfiguration() {
      AmbroExpress.tenantId = this.oidcUser.tenantId;
      AmbroExpress.id = uuidv4();
      AmbroExpress.urlAddress = this.getUrlAddress;
      this.setConfigSet({ response: AmbroExpress });
    },
  },
};
</script>
